/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../../components/seo"

import Page from "../../layouts/page"
import { Link } from "gatsby"

const styles = css`
iframe.document {
  width: 100%;
  height: 100vh;
}
`

const PrivacyPolicyPage = () => {
  return <Page>
    <div css={styles}>
      <div className="contain page-body">
        <ol class="uk-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/legal">Legal</Link></li>
          <li><span>Privacy</span></li>
        </ol>

        <h1>Privacy Policy</h1>

        <iframe class="document" src="/legal/privacy_policy.pdf"  title="Privacy Policy Document"></iframe>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Privacy Policy"
    path="/legal/privacy"
  />
)

export default PrivacyPolicyPage
